.logo {
    background-image: url("/public/logo.png");
    background-repeat: no-repeat;
    background-position: center;
    background-size: contain;
    height: 50px;    
    width: 50px;
  }
  
  .logo-container {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    flex-direction: row;
  }
  